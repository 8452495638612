import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabParameterService } from '../../../services/lab-parameter.service';
import { LabconfigurationService } from '../../../services/labconfiguration.service';
import { LabTestService } from '../../../services/lab-test.service';
import { RouteShareService } from '../../../services/route-share.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-share-parameter',
  templateUrl: './share-parameter.component.html',
  styleUrls: ['./share-parameter.component.css'],
})
export class ShareParameterComponent implements OnInit {
  @Input() isUpdated: any;
  @Input() parameterId: any;
  @Input() showConfirmation: any;
  LabParameterForm: FormGroup;
  accessParameterForm: FormGroup;
  SearchParameterForm: FormGroup;
  textReport: FormGroup;
  editorTextReport: FormGroup;
  comboReport: FormGroup;
  textReportsubmitted = false;
  comboReportsubmitted = false;
  newParametersubmitted = false;
  showCutoffAndPatientValue = false;
  submitted = false;
  searchSubmitted = false;
  allAccessRanges: any[];
  uoms = ['mg/dl', 'UI', 'g/dl', 'K/mcl', 'x10^9/L', '%', 'fl', 'mm/Hr'];
  labParameters: any[];
  labParameter: any;
  specimens = ['blood', 'urine', 'stool', 'milk'];
  result_type1s = ['decimal', 'string', 'text_area'];
  result_type2s = ['decimal', 'string', 'text_area'];
  age_types = ['years', 'months', 'days'];
  range_types = ['In Between', 'Less Then', 'Greater Then', 'Equal'];
  start_age_in_year = 0;
  end_age_in_year = 0;
  genders = ['male', 'female', 'Both'];
  selected_gender: string;
  selected_age_type: string;
  reportType = '';
  addDropdownValue1 = '';
  addDropdownValue1Array = [];
  addDropdownValue2 = '';
  addDropdownValue2Array = [];
  labConfigurations = [];
  lisMachine = [];
  labUOM = [];
  // labSpecimen = [];
  listData = [];
  addPerameter = true;
  updatePerameterId: any;
  screenName = '';
  access_ranges: any;
  createNewParameter = false;
  updateNewParameter = true;
  lab_investigation_id: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private labParameterService: LabParameterService,
    private labService: LabconfigurationService,
    private labTestService: LabTestService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }
  @Output() fetchAllLab: EventEmitter<boolean> = new EventEmitter();
  @Output() dataOfLab: EventEmitter<string[]> = new EventEmitter();
  @Output() returnParameter: EventEmitter<string[]> = new EventEmitter();
  @Output() returnUpdated: EventEmitter<string[]> = new EventEmitter();
  @Output() isUpdate: EventEmitter<boolean> = new EventEmitter();
  @Output() hideValues: EventEmitter<boolean> = new EventEmitter();
  @Output() newItemEvent = new EventEmitter<string>();

  ngOnInit(): any {
    $('#addMainParameter').on('hide.bs.modal', () => {});
    this.ngxService.stop();
    this.LabParameterForm = this.formBuilder.group({
      title: ['', Validators.required],
      parameter_code: [''],
      is_active: [true],
      uom: [''],
      specimen: [''],
      parameter_type: ['', Validators.required],
      range_comments: [''],
      machine_id: [''],
      machine_code: [''],
      show_cutoff_patient_value: [false],
      cutoff_value: [],
      patient_value: [],
    });
    this.SearchParameterForm = this.formBuilder.group({
      search_title: [''],
      search_code: ['', Validators.required],
      labParameter: [''],
      labParameters: [''],
    });
    this.textReport = this.formBuilder.group({
      parameter_code: [''],
      heading: [''],
    });
    this.editorTextReport = this.formBuilder.group({
      parameter_code: [''],
      heading: [''],
    });
    this.comboReport = this.formBuilder.group({
      parameter_code: [''],
      report_type_1: ['', Validators.required],
      report_type_2: [''],
      report_type_1_result: [''],
      report_type_2_result: [''],
    });
    this.addEmptyAccessRange();
    this.fetchLabConfigurators();
    this.fetchLabMachine();
    if (this.isUpdated) {
      this.searchLabPaarmeter(this.isUpdated);
    }
  }

  addAccessRangeGroup(): any {
    return this.formBuilder.group({
      gender: ['Both', Validators.required],
      parameter_code: [''],
      age_start: ['', Validators.required],
      age_end: ['years', Validators.required],
      range_type: ['in_between', Validators.required],
      start_range: ['', Validators.required],
      end_range: ['', Validators.required],
      age_type: ['', Validators.required],
      status: [false],
      heading: [''],
      range_title: [''],
      lab_assessment_id: [''],
    });
  }

  setLabAccessRanges(data): any {
    return this.formBuilder.group({
      gender: [data.gender, Validators.required],
      assess_investigation_id: [data.assess_investigation_id],
      // parameter_code: [data.parameter_code, Validators.required],
      age_start: [data.age_start, Validators.required],
      age_end: [data.age_end, Validators.required],
      range_type: [data.range_type, Validators.required],
      start_range: [data.start_range, Validators.required],
      end_range: [data.end_range, Validators.required],
      age_type: [data.age_type, Validators.required],
      status: [data.status],
      lab_assessment_id: [data.lab_assessment_id],
    });
  }

  get accessRangesArray(): any {
    return this.accessParameterForm.get('accessRanges') as FormArray;
  }

  addAccessRange(): any {
    this.accessRangesArray.push(this.addAccessRangeGroup());
    if (this.SearchParameterForm.controls.search_title.value !== '') {
      this.patchParameterCode(this.accessRangesArray.length - 1);
    }
  }
  removeAccessRange(accessIndex): any {
    if (this.accessRangesArray.length > 1) {
      this.accessRangesArray.removeAt(accessIndex);
    }
  }

  addEmptyAccessRange(): any {
    this.accessParameterForm = this.formBuilder.group({
      accessRanges: this.formBuilder.array([this.addAccessRangeGroup()]),
    });
  }
  setAllAccessRangeCode(): any {
    for (let j = 0; j < this.accessRangesArray.length; j++) {
      this.patchParameterCode(j);
      this.setGender(this.accessRangesArray.controls[j].value, j);
      this.updateRangesFilesValue(
        this.accessRangesArray.controls[j].value.range_type,
        j,
      );
    }
  }
  updateRangesFilesValue(type, i): any {
    if (type === 'in_between') {
      this.accessParameterForm
        .get(['accessRanges', i])
        .get('start_range')
        .enable();
      this.accessParameterForm
        .get(['accessRanges', i])
        .get('end_range')
        .enable();
    }
    if (type === 'less_then') {
      this.accessParameterForm
        .get(['accessRanges', i])
        .get('start_range')
        .disable();
      this.accessParameterForm
        .get(['accessRanges', i])
        .get('end_range')
        .enable();
    }
    if (type === 'greater_then' || type === 'equal') {
      this.accessParameterForm
        .get(['accessRanges', i])
        .get('start_range')
        .enable();
      this.accessParameterForm
        .get(['accessRanges', i])
        .get('end_range')
        .disable();
    }
  }
  setGender(accessRange, i): any {
    if (accessRange.gender === 'female') {
      this.accessParameterForm
        .get('accessRanges')
        [
          'controls'
        ][i].controls.gender.setValue(this.genders[1], { onlySelf: true });
    }
    if (accessRange.gender === 'male') {
      this.accessParameterForm
        .get('accessRanges')
        [
          'controls'
        ][i].controls.gender.setValue(this.genders[0], { onlySelf: true });
    }
    if (accessRange.gender === 'Both') {
      this.accessParameterForm
        .get('accessRanges')
        [
          'controls'
        ][i].controls.gender.setValue(this.genders[2], { onlySelf: true });
    }
  }
  searchLabPaarmeter(test_id): any {
    this.addEmptyAccessRange();
    this.ngxService.start();
    this.reportType = '';
    this.addDropdownValue1Array = [];
    this.addDropdownValue2Array = [];
    this.labParameterService.search_lab_parameter_by_idV2(test_id).subscribe(
      (data) => {
        this.SearchParameterForm.controls.search_code.setValue(
          data.lab_parameter.parameter_code,
        );
        this.updateTest(data.lab_parameter);
        this.reportType = data.lab_parameter.parameter_type;
        this.labParameter = data.lab_parameter;
        this.lab_investigation_id = data.lab_parameter.lab_investigation_id;
        $('#RefValue').val(this.reportType);
        if (this.reportType === 'text') {
          this.textReport.controls.parameter_code.setValue(
            data.lab_parameter.parameter_code,
          );
          this.textReport.controls.heading.setValue(data.heading || '');
        }
        if (this.reportType === 'editor') {
          this.editorTextReport.controls.parameter_code.setValue(
            data.lab_parameter.parameter_code,
          );
          this.editorTextReport.controls.heading.setValue(data.heading || '');
        }
        if (this.reportType === 'combo') {
          this.comboReport.controls.parameter_code.setValue(
            data.lab_parameter.parameter_code,
          );
          this.comboReport.controls.report_type_1.setValue(
            data.lab_ranges.report_type_1,
          );
          this.comboReport.controls.report_type_2.setValue(
            data.lab_ranges.report_type_2,
          );
          this.comboReport.controls.report_type_1_result.setValue(
            data.lab_ranges.report_type_1_result,
          );
          this.comboReport.controls.report_type_2_result.setValue(
            data.lab_ranges.report_type_2_result,
          );
          if (data.lab_ranges.report_type_1 === 'decimal') {
            $('#RefValue').val('comboNumber');
          }
          if (data.lab_ranges.report_type_2 === 'decimal') {
            $('#RefValue').val('comboNumber');
          }
          if (data.lab_ranges.report_type_1 === 'dropdown') {
            this.addDropdownValue1Array = data.lab_ranges.report_type_1_result;
            $('#RefValue').val('comboDropdown');
          }
          if (data.lab_ranges.report_type_2 === 'dropdown') {
            this.addDropdownValue2Array = data.lab_ranges.report_type_2_result;
            $('#RefValue').val('comboDropdown');
          }
        }
        if (this.reportType === 'single') {
          this.setParameterAccessRanges(data);
        }

        this.ngxService.stop();
      },
      (err) => {
        this.clearAccesRangeArray();
        this.addEmptyAccessRange();
        this.clearSearchTitle();
        this.ngxService.stop();
        toastr.error(err.error.message);
      },
    );
  }
  clearSearchTitle(): any {
    this.SearchParameterForm.controls.search_title.setValue('');
  }
  setParameterAccessRanges(data): any {
    if (data.lab_parameter) {
      if (this.labParameter.parameter_code !== '') {
        this.SearchParameterForm.controls.search_title.setValue(
          this.labParameter.id,
        );
      }
    }
    if (data.lab_access_ranges.length > 0) {
      this.clearAccesRangeArray();
      this.allAccessRanges = data.lab_access_ranges;
      this.editAccessRanges();
      this.setAllAccessRangeCode();
    } else {
      for (let j = 0; j < this.accessRangesArray.length; j++) {
        this.patchParameterCode(j);
      }
    }
    this.ngxService.stop();
  }
  editAccessRanges(): any {
    this.accessRangesArray.removeAt(0);
    for (let i = 0; i < this.allAccessRanges.length; i++) {
      this.calculateAge(this.allAccessRanges[i]);
      this.accessRangesArray.push(
        this.setLabAccessRanges(this.allAccessRanges[i]),
      );
    }
  }
  patchParameterCode(i): any {
    // if (this.accessRangesArray.length > 0) {
    this.accessRangesArray.at(i).patchValue({
      parameter_code: this.labParameter.parameter_code,
    });
    // }
  }
  clearAccesRangeArray(): any {
    while (this.accessRangesArray.length) {
      this.accessRangesArray.removeAt(0);
    }
  }
  calculateAge(labAccessRange): any {
    const end_age = labAccessRange.age_end;
    const start_age = labAccessRange.age_start;
    if (end_age < 365) {
      this.end_age_in_year = end_age / 30;
      this.start_age_in_year = start_age / 30;
      this.selected_age_type = 'months';
    } else {
      this.end_age_in_year = end_age / 365;
      this.start_age_in_year = start_age / 365;
      this.selected_age_type = 'years';
    }
  }
  verify(): any {
    let comments = '';
    if ($('#rangeComments').val().trim() !== '') {
      comments = $('#rangeComments')
        .val()
        .replace(/(\r\n|\n)/g, '<br/>');
    }
    this.LabParameterForm.controls.range_comments.setValue(comments);
    this.newParametersubmitted = true;
    this.access_ranges = '';
    if (this.LabParameterForm.invalid) {
      return;
    }
    if (this.LabParameterForm.get('parameter_type').value === 'text') {
      this.access_ranges = this.textReport.value;
    }
    if (this.LabParameterForm.get('parameter_type').value === 'editor') {
      this.access_ranges = this.editorTextReport.value;
    }
    if (this.LabParameterForm.get('parameter_type').value === 'single') {
      this.submitted = true;
      this.searchSubmitted = true;
      if (this.accessParameterForm.invalid) {
        return;
      }
      this.access_ranges = this.accessParameterForm.get('accessRanges').value;
    }
    if (this.LabParameterForm.get('parameter_type').value === 'combo') {
      this.access_ranges = this.comboReport.value;
    }
    if (this.showConfirmation) {
      $('#Confirm').modal('show');
    } else {
      this.updatePerameterFun();
    }
  }
  newLabParameter(): any {
    this.newParametersubmitted = true;
    this.access_ranges = '';
    if (this.LabParameterForm.invalid) {
      return;
    }
    if (this.LabParameterForm.get('parameter_type').value === 'text') {
      this.textReportsubmitted = true;
      if (this.textReport.invalid) {
        return;
      }
      this.access_ranges = this.textReport.value;
    }
    if (this.LabParameterForm.get('parameter_type').value === 'editor') {
      this.textReportsubmitted = true;
      if (this.textReport.invalid) {
        return;
      }
      this.access_ranges = this.editorTextReport.value;
    }
    if (this.LabParameterForm.get('parameter_type').value === 'single') {
      this.submitted = true;
      this.searchSubmitted = true;
      if (this.accessParameterForm.invalid) {
        return;
      }
      this.access_ranges = this.accessParameterForm.get('accessRanges').value;
    }
    if (this.LabParameterForm.get('parameter_type').value === 'combo') {
      this.comboReportsubmitted = true;
      if (this.comboReport.invalid) {
        return;
      }
      this.access_ranges = this.comboReport.value;
    }
    let comments = '';
    if ($('#rangeComments').val().trim() !== '') {
      comments = $('#rangeComments')
        .val()
        .replace(/(\r\n|\n)/g, '<br/>');
    }
    this.LabParameterForm.controls.range_comments.setValue(comments);
    //$('#Confirm').modal('hide');
    if (this.addPerameter === true) {
      this.addPerameterFun();
    } else {
      this.updatePerameterFun();
    }
  }
  addPerameterFun(): any {
    this.ngxService.start();
    this.labParameterService
      .createV2(this.LabParameterForm.value, this.access_ranges)
      .subscribe(
        (data) => {
          this.ngxService.stop();
          this.fetchAllLab.emit(true);
          this.returnParameter.emit(data.lab_assessment);
          this.hideValues.emit(true);
          this.newItemEvent.emit('true');
          this.clearPerameterValues();
          toastr.success('Successfully saved new lab parameter.');

          $('#addMainParameter').modal('hide');
        },
        (err) => {
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }
  updatePerameterFun(): any {
    this.ngxService.start();

    this.labParameterService
      .updateV2(
        this.LabParameterForm.value,
        this.access_ranges,
        this.updatePerameterId,
        this.createNewParameter,
        this.updateNewParameter,
        this.parameterId,
        this.lab_investigation_id,
      )
      .subscribe(
        (data) => {
          this.ngxService.stop();
          this.clearPerameterValues();
          this.returnParameter.emit(data.assessment_ranges);
          this.returnUpdated.emit(data.is_updated);
          this.isUpdate.emit(true);
          this.fetchAllLab.emit(false);
          this.hideValues.emit(true);
          this.newItemEvent.emit('true');
          $('#Confirm').modal('hide');
          $('#addMainParameter').modal('hide');
          toastr.success('Successfully saved new lab parameter.');
        },
        (err) => {
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }

  onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  addComboValue1(): any {
    if (this.addDropdownValue1 !== '') {
      this.addDropdownValue1Array.push(this.addDropdownValue1);
      this.comboReport.controls.report_type_1_result.setValue(
        this.addDropdownValue1Array,
      );
      this.addDropdownValue1 = '';
    }
  }
  removeComboValue1(i): any {
    this.addDropdownValue1Array.splice(i, 1);
    this.comboReport.controls.report_type_1_result.setValue(
      this.addDropdownValue1Array,
    );
  }
  addComboValue2(): any {
    if (this.addDropdownValue2 !== '') {
      this.addDropdownValue2Array.push(this.addDropdownValue2);
      this.comboReport.controls.report_type_2_result.setValue(
        this.addDropdownValue2Array,
      );
      this.addDropdownValue2 = '';
    }
  }
  removeComboValue2(i): any {
    this.addDropdownValue2Array.splice(i, 1);
    this.comboReport.controls.report_type_2_result.setValue(
      this.addDropdownValue2Array,
    );
  }

  fetchLabConfigurators(): any {
    this.ngxService.start();
    this.labService.getLabSpecificConfigurator('uom').subscribe(
      (data) => {
        // console.log(data);
        this.labConfigurations = data.lab_configurations;
        this.labUOM = this.labConfigurations.filter(
          (a) => a.configurator_type === 'uom',
        );
        this.ngxService.stop();
        setTimeout(() => {
          $('#uomParameter').selectpicker('refresh');
          $('#uomParameter').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.message);
      },
    );
  }

  fetchLabMachine(): any {
    this.labService
      .getLabSpecificConfiguratorV2('lis_machine', 'true', '', '', '')
      .subscribe(
        (data) => {
          this.lisMachine = data.lab_configurations;
        },
        (err) => {
          toastr.error(err.error.message);
        },
      );
  }

  clearPerameterValues(): any {
    this.LabParameterForm.reset({
      title: '',
      parameter_code: '',
      is_active: true,
      uom: '',
      specimen: '',
      parameter_type: '',
      machine_id: '',
      machine_code: '',
      show_cutoff_patient_value: false,
      cutoff_value: '',
      patient_value: '',
    });
    this.showCutoffAndPatientValue = false;
    this.newParametersubmitted = false;
    this.textReportsubmitted = false;
    this.submitted = false;
    this.searchSubmitted = false;
    this.comboReportsubmitted = false;
    setTimeout(() => {
      $('#uomParameter').selectpicker('refresh');
      $('#uomParameter').selectpicker();
      $('#rangeComments').val('');
      $('#RefValue').val('');
    }, 500);
  }
  updateTest(element): any {
    this.addPerameter = false;
    // console.log('element', element);
    this.updatePerameterId = element.id;
    this.LabParameterForm.controls.title.setValue(element.title);
    this.LabParameterForm.controls.parameter_code.setValue(
      element.parameter_code,
    );
    this.LabParameterForm.controls.is_active.setValue(element.is_active);
    this.LabParameterForm.controls.uom.setValue(element.uom);
    this.LabParameterForm.controls.specimen.setValue(element.specimen);
    this.LabParameterForm.controls.parameter_type.setValue(
      element.parameter_type,
    );
    this.LabParameterForm.controls.range_comments.setValue('');
    this.LabParameterForm.controls.machine_id.setValue(element.machine_id);
    this.LabParameterForm.controls.machine_code.setValue(element.machine_code);
    this.LabParameterForm.controls.show_cutoff_patient_value.setValue(
      element.show_cutoff_patient_value,
    );
    this.LabParameterForm.controls.cutoff_value.setValue(element.cutoff_value);
    this.LabParameterForm.controls.patient_value.setValue(
      element.patient_value,
    );
    $('#rangeComments').val(
      element.range_comments.replace(/<br\s*\/?>/gm, '\n'),
    );
    this.showCutoffAndPatientValue = element.show_cutoff_patient_value;
  }
  updateRangeType(type, i): any {
    this.accessParameterForm
      .get(['accessRanges', i])
      .get('start_range')
      .patchValue('');
    this.accessParameterForm
      .get(['accessRanges', i])
      .get('end_range')
      .patchValue('');
    if (type === 'in_between') {
      this.accessParameterForm
        .get(['accessRanges', i])
        .get('start_range')
        .enable();
      this.accessParameterForm
        .get(['accessRanges', i])
        .get('end_range')
        .enable();
    }
    if (type === 'less_then') {
      this.accessParameterForm
        .get(['accessRanges', i])
        .get('start_range')
        .disable();
      this.accessParameterForm
        .get(['accessRanges', i])
        .get('end_range')
        .enable();
    }
    if (type === 'greater_then' || type === 'equal') {
      this.accessParameterForm
        .get(['accessRanges', i])
        .get('start_range')
        .enable();
      this.accessParameterForm
        .get(['accessRanges', i])
        .get('end_range')
        .disable();
    }
  }

  get f(): any {
    return this.LabParameterForm.controls;
  }
  get s(): any {
    return this.SearchParameterForm.controls;
  }
  get testRep(): any {
    return this.textReport.controls;
  }
  get comboRep(): any {
    return this.comboReport.controls;
  }
  updateParameterType(data): any {
    if (data.value === 'comboDropdown') {
      this.LabParameterForm.get('parameter_type').setValue('combo');
      this.comboReport.get('report_type_1').setValue('dropdown');
    } else if (data.value === 'comboNumber') {
      this.LabParameterForm.get('parameter_type').setValue('combo');
      this.comboReport.get('report_type_1').setValue('decimal');
    } else {
      this.LabParameterForm.get('parameter_type').setValue(data.value);
    }
    if (data.value === 'editor' || data.value === 'single') {
      this.LabParameterForm.get('show_cutoff_patient_value').setValue(false);
      this.LabParameterForm.get('cutoff_value').setValue('');
      this.LabParameterForm.get('patient_value').setValue('');
      this.showCutoffAndPatientValue = false;
    }
  }
  changeCreateToggle(): any {
    this.updateNewParameter = this.createNewParameter !== true;
    this.createNewParameter = this.updateNewParameter === false;
  }
  changeUpdateToggle(): any {
    this.createNewParameter = this.updateNewParameter === false;
  }
  closeConfirmModal(): any {
    this.newItemEvent.emit('false');
    this.hideValues.emit(false);
    $('#Confirm').modal('hide');
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9 .]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
}
