<!--begin::Content-->
<div class="d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <!--  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">-->
  <div
    class="subheader p-2 subheader-solid"
    id="kt_subheader"
    style="height: auto"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-baseline justify-content-between flex-wrap mr-5 w-100"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <!--          <button class="btn btn-sm btn-primary" [routerLink]="['/roster/shifts-detail']"><i class="fas fa-long-arrow-alt-left"></i> Back</button>-->
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--begin::Actions-->
        <!--        <a  class="btn btn-light-primary font-weight-bolder btn-sm">Actions</a>-->
        <!--end::Actions-->
        <!--begin::Dropdown-->
        <!--        <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left">-->
        <!--          <a  class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--											<span class="svg-icon svg-icon-success svg-icon-2x">-->
        <!--												&lt;!&ndash;begin::Svg Icon | path:assets/media/svg/icons/Files/File-plus.svg&ndash;&gt;-->
        <!--												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
        <!--													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
        <!--														<polygon points="0 0 24 0 24 24 0 24" />-->
        <!--														<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />-->
        <!--														<path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000" />-->
        <!--													</g>-->
        <!--												</svg>-->
        <!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--											</span>-->
        <!--          </a>-->
        <!--          <div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">-->
        <!--            &lt;!&ndash;begin::Navigation&ndash;&gt;-->
        <!--            <ul class="navi navi-hover">-->
        <!--              <li class="navi-header font-weight-bold py-4">-->
        <!--                <span class="font-size-lg">Choose Label:</span>-->
        <!--                <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mb-3 opacity-70"></li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-success">Customer</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-danger">Partner</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-warning">Suplier</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-primary">Member</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-dark">Staff</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mt-3 opacity-70"></li>-->
        <!--              <li class="navi-footer py-4">-->
        <!--                <a class="btn btn-clean font-weight-bold btn-sm" >-->
        <!--                  <i class="ki ki-plus icon-sm"></i>Add new</a>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--            &lt;!&ndash;end::Navigation&ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
        <!--end::Dropdown-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::Card-->
      <div class="card card-custom mb-5">
        <div class="card-body p-5">
          <div class="row">
            <!--Select Month-->
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <div>
                <label>Select Month <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm month"
                  placeholder="Select Month"
                  readonly="readonly"
                  [(ngModel)]="month"
                />
              </div>
            </div>
            <!--Department-->
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <div>
                <label>Department: <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm selectpicker"
                  multiple
                  data-live-search="true"
                  data-container="body"
                  id="department"
                  name="department"
                  (change)="getEmployeesOfDepartment()"
                  [(ngModel)]="department"
                >
                  <option value="" [disabled]="true">Select Department</option>
                  <ng-container *ngFor="let dep of depart">
                    <option [value]="dep.id">{{ dep.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--Employee Of Department-->
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 m-0">
              <div>
                <label>Employee:</label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-live-search="true"
                  data-container="body"
                  multiple
                  id="employeeDepartmentList"
                  name="department"
                  [(ngModel)]="employeesOfSelectedDepartment"
                >
                  <option value="" [disabled]="true">Select Employee</option>
                  <ng-container *ngFor="let emp of employeesOfDepart">
                    <option [value]="emp.id">
                      {{ emp?.employee_file_no }} - {{ emp?.first_name }}
                      {{ emp?.middle_name || "" }} {{ emp?.last_name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
              <div>
                <span
                  class="btn btn-light-primary btn-sm"
                  type="button"
                  (click)="appendArray()"
                  >Search</span
                >
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <div class="color-codes">
                <div class="day-off-color"><span></span></div>
                <div class="name"><strong>Day Off</strong></div>
              </div>
              <div class="color-codes">
                <div class="gazetted-color"><span></span></div>
                <div class="name"><strong>Gazetted Day</strong></div>
              </div>
              <div class="color-codes">
                <div class="overlap-color"><span></span></div>
                <div class="name"><strong>Overlap</strong></div>
              </div>
            </div>
          </div>
          <!--shifts-->
        </div>
      </div>
      <!--end::Card-->

      <div class="card card-custom" *ngIf="employeeRoasterData.length > 0">
        <div class="card-body p-5">
          <div
            class="employeeShiftTable"
            style="
              overflow-x: auto;
              position: relative;
              max-height: calc(100vh - 305px);
            "
          >
            <table class="table table-head-custom table-vertical-center">
              <thead>
                <tr class="rosterDates">
                  <th
                    style="
                      min-width: 135px;
                      width: 135px;
                      background: #fff;
                      position: sticky;
                      left: 0;
                      z-index: 09;
                      top: 0;
                    "
                  >
                    <a
                      class="btn btn-sm btn-clean btn-icon btn-light btn-hover-primary mr-2"
                      title="Move Roster"
                      (click)="singleEmployeeShiftToAllOthers()"
                    >
                      <i class="fas fa-people-arrows"></i>
                    </a>
                    <a
                      class="btn btn-sm btn-clean btn-icon btn-light btn-hover-primary"
                      title="Copy Roster"
                      (click)="openCopyRosterPopup()"
                    >
                      <i class="far fa-copy"></i>
                    </a>
                  </th>
                  <th
                    style="
                      min-width: 135px;
                      width: 135px;
                      position: sticky;
                      position: -webkit-sticky;
                      top: 0;
                      background: #fff;
                    "
                    *ngFor="let data of employeeRoasterData[0]?.roster_detail"
                  >
                    {{ data.show_date | customDateConversion }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let data of employeeRoasterData; let i = index"
                  id="rosterValue{{ i }}"
                  class="rosterShiftTop"
                >
                  <td style="position: sticky; left: 0; background: #fff">
                    <div
                      style="
                        display: flex;
                        min-height: 70px;
                        align-items: center;
                      "
                    >
                      <span class="apply-del-btn mr-3">
                        <a
                          class="btn btn-sm btn-clean btn-icon btn-light btn-hover-primary mb-2"
                          title="Calender"
                          (click)="
                            openApplyToAllModal(
                              i,
                              data.employee,
                              data.is_closed,
                              data.employee_name
                            )
                          "
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <i class="la la-download"></i>
                          </span>
                        </a>
                        <a
                          class="btn btn-sm btn-clean btn-icon btn-light btn-hover-primary mb-2"
                          title="Delete"
                          (click)="removefromArray(i)"
                        >
                          <span class="svg-icon svg-icon-md">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </a>
                        <a
                          class="btn btn-sm btn-clean btn-icon btn-light btn-hover-primary"
                          title="Day Off"
                          (click)="
                            selectDayOff(i, data.is_closed, data.employee_name)
                          "
                        >
                          <i class="fas fa-calendar-day"></i>
                        </a>
                      </span>
                      <div
                        class="d-flex font-weight-bold"
                        style="flex-direction: column"
                      >
                        <span
                          class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                          style="word-break: break-word"
                          >{{ data?.employee_name }}</span
                        >
                        <span
                          class="text-muted font-weight-bold text-muted d-block"
                          style="word-break: break-word"
                          >{{ data?.designation }}</span
                        >
                        <span
                          class="text-muted font-weight-bold text-muted d-block"
                          >{{ data?.employee_file_no }}</span
                        >
                      </div>
                    </div>
                  </td>
                  <th
                    *ngFor="let roster of data.roster_detail; let j = index"
                    class="rosterShiftMain"
                  >
                    <div
                      class="rosterShift employeeShiftTableList"
                      style="overflow: auto; padding: 5px"
                      (click)="
                        singleShiftModalOpen(
                          i,
                          j,
                          $event,
                          data.is_closed,
                          data.employee_name
                        )
                      "
                      id="{{ i }}roster{{ j }}"
                      [ngClass]="{
                        is_overlap: roster?.is_overlap === true,
                        is_gazetted_off: roster?.is_gazetted_off === true,
                        is_day_off: roster?.is_week_off || roster?.is_day_off
                      }"
                    >
                      <div class="leave-days" *ngIf="roster.is_leave">
                        leave
                      </div>
                      <ng-container
                        *ngFor="let shiftData of roster?.shift; let k = index"
                      >
                        <span
                          *ngIf="shiftData?.is_removed === false"
                          class="shift-design"
                          [style.background-color]="shiftData?.shift_bg_color"
                          ngbPopover="{{ shiftData?.user_name }}"
                          triggers="mouseenter:mouseleave"
                          placement="bottom"
                          [style.color]="shiftData?.shift_text_color"
                          style="height: auto"
                          (click)="$event.stopPropagation()"
                        >
                          <span>
                            {{ shiftData?.shift_name }} -
                            {{ shiftData?.location_name }}
                          </span>
                          <i
                            *ngIf="
                              shiftData?.can_delete === true &&
                              shiftData?.status === 0
                            "
                            style="font-size: 7px; line-height: 1"
                            aria-hidden="true"
                            class="ki ki-close remove-shift"
                            [style.color]="shiftData?.shift_text_color"
                            (click)="
                              openRemoveModal(
                                i,
                                j,
                                k,
                                shiftData?.shift_id,
                                data.is_closed,
                                data.employee_name
                              );
                              singleShiftModalOpen(i, j, $event, data.is_closed)
                            "
                          ></i>
                          <i
                            *ngIf="shiftData?.status === 1"
                            style="font-size: 7px; line-height: 1"
                            aria-hidden="true"
                            class="fas fa-check"
                            [style.color]="shiftData?.shift_text_color"
                          ></i>
                        </span>
                      </ng-container>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="text-right mt-3" *ngIf="employeeRoasterData.length > 0">
        <!--        <span class="btn btn-primary btn-sm mr-5" type="button" (click)="openCopyRosterPopup()">Copy Roster</span>-->
        <span
          class="btn btn-hover-text-white btn-light-danger btn-sm mr-3"
          type="button"
          *ngIf="this.isrosterclose === true"
          (click)="confirmClose()"
          >Close</span
        >
        <span
          class="btn btn-primary btn-sm"
          type="button"
          (click)="onSubmit(false)"
          >Save</span
        >
      </div>
    </div>
  </div>
</div>
<!--end::Content-->

<!-- Modal-->
<div
  class="modal fade"
  id="rosterApplyToAll"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Apply to All</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-5">
            <div class="form-group">
              <label>Shift: <span class="text-danger">*</span></label>
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                name="selectedLocation"
                [(ngModel)]="selectedShiftAdd"
              >
                <option value="" [disabled]="true">Select Shift</option>
                <ng-container *ngFor="let shift of shiftData">
                  <option [value]="shift.id">
                    {{ shift.name }} - [{{ shift?.time_from }} -
                    {{ shift?.time_to }}]
                  </option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="col-sm-5">
            <div class="form-group">
              <label>Location: <span class="text-danger">*</span></label>
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                name="selectedLocation"
                [(ngModel)]="selectedLocationAdd"
              >
                <option value="" [disabled]="true">Select Location</option>
                <ng-container *ngFor="let location of locationData">
                  <option [value]="location.id">{{ location?.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="col-2" style="align-self: end">
            <div class="form-group">
              <div
                class="btn btn-primary btn-sm"
                (click)="getSingleEmployeeRosterDetail()"
              >
                Get
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <div class="checkbox-horizental">
                <span class="d-flex align-items-center">
                  <label class="checkbox mr-4">
                    <input
                      type="checkbox"
                      (change)="
                        getDaysOfWeekHorizental(1, $event.target.checked)
                      "
                    />
                    <span></span>
                  </label>
                </span>
                <span class="d-flex align-items-center">
                  <label class="checkbox mr-4">
                    <input
                      type="checkbox"
                      (change)="
                        getDaysOfWeekHorizental(2, $event.target.checked)
                      "
                    />
                    <span></span>
                  </label>
                </span>
                <span class="d-flex align-items-center">
                  <label class="checkbox mr-4">
                    <input
                      type="checkbox"
                      (change)="
                        getDaysOfWeekHorizental(3, $event.target.checked)
                      "
                    />
                    <span></span>
                  </label>
                </span>
                <span class="d-flex align-items-center">
                  <label class="checkbox mr-4">
                    <input
                      type="checkbox"
                      (change)="
                        getDaysOfWeekHorizental(4, $event.target.checked)
                      "
                    />
                    <span></span>
                  </label>
                </span>
                <span class="d-flex align-items-center">
                  <label class="checkbox mr-4">
                    <input
                      type="checkbox"
                      (change)="
                        getDaysOfWeekHorizental(5, $event.target.checked)
                      "
                    />
                    <span></span>
                  </label>
                </span>
                <span class="d-flex align-items-center">
                  <label class="checkbox mr-4">
                    <input
                      type="checkbox"
                      (change)="
                        getDaysOfWeekHorizental(6, $event.target.checked)
                      "
                    />
                    <span></span>
                  </label>
                </span>
                <span class="d-flex align-items-center">
                  <label class="checkbox mr-4">
                    <input
                      type="checkbox"
                      (change)="
                        getDaysOfWeekHorizental(7, $event.target.checked)
                      "
                    />
                    <span></span>
                  </label>
                </span>
              </div>
              <div class="d-flex">
                <div class="rosterDaysBox">
                  <span
                    class="d-flex align-items-center"
                    *ngIf="verticalCount > 0"
                  >
                    <label class="checkbox mr-4">
                      <input
                        type="checkbox"
                        (change)="getDaysOfWeek(1, $event.target.checked)"
                      />
                      <span></span>
                    </label>
                  </span>
                  <span
                    class="d-flex align-items-center"
                    *ngIf="verticalCount > 1"
                  >
                    <label class="checkbox mr-4">
                      <input
                        type="checkbox"
                        (change)="getDaysOfWeek(2, $event.target.checked)"
                      />
                      <span></span>
                    </label>
                  </span>
                  <span
                    class="d-flex align-items-center"
                    *ngIf="verticalCount > 2"
                  >
                    <label class="checkbox mr-4">
                      <input
                        type="checkbox"
                        (change)="getDaysOfWeek(3, $event.target.checked)"
                      />
                      <span></span>
                    </label>
                  </span>
                  <span
                    class="d-flex align-items-center"
                    *ngIf="verticalCount > 3"
                  >
                    <label class="checkbox mr-4">
                      <input
                        type="checkbox"
                        (change)="getDaysOfWeek(4, $event.target.checked)"
                      />
                      <span></span>
                    </label>
                  </span>
                  <span
                    class="d-flex align-items-center"
                    *ngIf="verticalCount > 4"
                  >
                    <label class="checkbox mr-4">
                      <input
                        type="checkbox"
                        (change)="getDaysOfWeek(5, $event.target.checked)"
                      />
                      <span></span>
                    </label>
                  </span>
                  <span
                    class="d-flex align-items-center"
                    *ngIf="verticalCount > 5"
                  >
                    <label class="checkbox mr-4">
                      <input
                        type="checkbox"
                        (change)="getDaysOfWeek(6, $event.target.checked)"
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
                <!--                <input id="rosterCalendar">-->
                <div id="rosterCalendar"></div>
              </div>
            </div>
          </div>
          <!--Shift Confirmation-->
          <div class="col-12">
            <span class="d-flex align-items-center">
              <label class="checkbox mr-4">
                <input type="checkbox" [(ngModel)]="applyToAllConfirmation" />
                <span></span>
              </label>
              <span>Are You sure you want to apply to all shifts.</span>
            </span>
          </div>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary font-weight-bold"
          (click)="saveModalChanges()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

<!-- singleShiftModalClick-->
<div
  class="modal fade"
  id="singleShiftModalClick"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header">
        <h5 class="modal-title">Create Single shift</h5>
        <a type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </a>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label>Shift: <span class="text-danger">*</span></label>
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                data-container="body"
                name="selectedLocation"
                [(ngModel)]="selectedShiftAdd"
              >
                <option value="" [disabled]="true">Select Shift</option>
                <ng-container *ngFor="let shift of shiftData">
                  <option [value]="shift.id">
                    {{ shift.name }} - [{{ shift?.time_from }} -
                    {{ shift?.time_to }}]
                  </option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label>Location: <span class="text-danger">*</span></label>
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                data-container="body"
                name="selectedLocation"
                [(ngModel)]="selectedLocationAdd"
              >
                <option value="" [disabled]="true">Select Location</option>
                <ng-container *ngFor="let location of locationData">
                  <option [value]="location.id">{{ location?.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <a
          type="button"
          class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
          >Close</a
        >
        <button
          type="submit"
          class="btn btn-primary font-weight-bold"
          (click)="addNewShiftToEmployee()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

<!-- RemoveSingleShiftModalClick-->
<div
  class="modal fade"
  id="singleShiftModalRemoveClick"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header">
        <h5 class="modal-title">Remove Single shift Confirmation</h5>
        <a type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </a>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <span class="d-flex align-items-center">
              <label class="checkbox mr-4">
                <input
                  type="checkbox"
                  [(ngModel)]="removeSingleEmployeeShift"
                />
                <span></span>
              </label>
              <span>Are You sure you want to remove the shifts.</span>
            </span>
          </div>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <a
          type="button"
          class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
          >Close</a
        >
        <button
          type="submit"
          class="btn btn-primary font-weight-bold"
          (click)="removeAllSingleShiftModal()"
        >
          All
        </button>
        <button
          type="submit"
          class="btn btn-light-primary font-weight-bold"
          (click)="removeSingleShiftModal()"
        >
          Current
        </button>
      </div>
    </div>
  </div>
</div>

<!-- singleEmployeeShiftToAllOthers-->
<div
  class="modal fade"
  id="singleEmployeeShiftToAllOthers"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header">
        <h5 class="modal-title">Apply Shifts to Selected Employees</h5>
        <a type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </a>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label>From</label>
              <select
                class="form-control form-control-sm fromEmployee"
                [(ngModel)]="fromEmployee"
                data-live-search="true"
              >
                <option [ngValue]="''" disabled>Select</option>
                <option
                  *ngFor="let data of employeeRoasterData"
                  [ngValue]="data.employee"
                >
                  {{ data.employee_name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label>To</label>
              <select
                class="form-control form-control-sm toEmployee"
                [(ngModel)]="toEmployee"
                data-live-search="true"
                multiple
                (change)="getAllProduct($event.target.value)"
              >
                <option [ngValue]="''" disabled>Select</option>
                <option [ngValue]="-1" [disabled]="showDeselectAll">
                  Select All
                </option>
                <option [ngValue]="-2" [disabled]="showSelectAll">
                  De-select All
                </option>
                <option
                  *ngFor="let data of employeeRoasterData"
                  [ngValue]="data.employee"
                  [disabled]="data.is_closed"
                >
                  {{ data?.employee_file_no }} - {{ data.employee_name }} -
                  <span *ngIf="data.is_closed">Closed</span>
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <a
          type="button"
          class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
          >Close</a
        >
        <button
          type="submit"
          class="btn btn-primary font-weight-bold"
          (click)="submitSingleEmployeeShiftToAllOthers()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<!-- copy Roster-->
<div
  class="modal fade"
  id="copyRoster"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header">
        <h5 class="modal-title">Copy Roster</h5>
        <a type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </a>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col-4">
            <div>
              <label>Employee: <span class="text-danger">*</span></label>
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                data-container="body"
                id="copyRosterEmployee"
                [(ngModel)]="copyRosterEmployee"
              >
                <option value="" [disabled]="true">Select Employee</option>
                <option
                  *ngFor="let data of employeeRoasterData"
                  [ngValue]="data.employee"
                  [disabled]="data.is_closed"
                >
                  {{ data?.employee_file_no }} - {{ data.employee_name }} -
                  <span *ngIf="data.is_closed">Closed</span>
                </option>
                <!--                <ng-container *ngFor="let emp of selectedEmployeeForRosterCopy">-->
                <!--                  <option *ngFor="let data of employeeRoasterData" [value]="emp.id" [disabled]="data?.is_closed"> {{emp?.employee_file_no}} - {{emp?.first_name}} {{emp?.middle_name || ''}} {{emp?.last_name}} - <span *ngIf="data?.is_closed">Closed</span></option>-->
                <!--                </ng-container>-->
              </select>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label>From Month <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm fromMonth"
                placeholder="Select Month"
                readonly="readonly"
                [(ngModel)]="fromMonth"
              />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label>To Month <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm toMonth"
                placeholder="Select Month"
                readonly="readonly"
                [(ngModel)]="toMonth"
              />
            </div>
          </div>
          <div class="col-12">
            <span class="d-flex align-items-center">
              <label class="checkbox mr-4">
                <input type="checkbox" [(ngModel)]="copyRosterCheck" />
                <span></span>
              </label>
              <span
                >Are You sure you want to copy Roster of {{ fromMonth }} to
                {{ toMonth }}?</span
              >
            </span>
          </div>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <a
          type="button"
          class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
          >Close</a
        >
        <button
          type="submit"
          class="btn btn-primary font-weight-bold"
          (click)="copyRosterSubmit()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<!-- copy Roster-->
<div
  class="modal fade"
  id="dayOff"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header">
        <h5 class="modal-title">Select Days Off</h5>
        <a type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </a>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Select Days</label>
              <select
                class="form-control form-control-sm"
                id="betweenDates"
                multiple
                [(ngModel)]="rosterDaysOff"
              >
                <option [ngValue]="''" disabled>Select</option>
                <option
                  *ngFor="let dates of betweenDates"
                  [disabled]="dates?.is_week_off"
                  [ngValue]="dates?.new_date"
                >
                  {{ dates?.new_date }}
                  <span *ngIf="dates?.is_week_off">(Weekly Off)</span>
                </option>
              </select>
            </div>
          </div>
          <div class="col-12">
            <span class="d-flex align-items-center">
              <label class="checkbox mr-4">
                <input type="checkbox" [(ngModel)]="rosterDaysOffCheck" />
                <span></span>
              </label>
              <span>Are You sure you want to Add these Days Off ?</span>
            </span>
          </div>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <a
          type="button"
          class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
          >Close</a
        >
        <button
          type="submit"
          class="btn btn-primary font-weight-bold"
          (click)="rosterDayOffSubmit()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="closeConfirmModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Close Roster Confirmation</h4>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="row">
          <div class="pl-2">Are you sure you want to close roster?</div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" data-dismiss="modal">
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          (click)="onConfirmRosterClose()"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
