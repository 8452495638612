import { Component, Input, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-report-customize-one',
  templateUrl: './report-customize-one.component.html',
  styleUrls: ['./report-customize-one.component.css'],
})
export class ReportCustomizeOneComponent implements OnInit {
  @Input() pageDataList: any;
  @Input() groupIndex: any;
  isUomShow = false;
  isRefShow = false;
  resultData = [];
  editor: any;
  cutoffValue: any;
  result: any;
  historyDates = [];
  showResult = false;
  showSutoffValue = false;
  showEditor = false;
  instructionDetails: any;
  showLabTestName = true;
  constructor() {}
  filterDataArray(dataArray) {
    return dataArray.filter((item) => {
      if (item.report_type === 'text' && item?.heading) {
        return true;
      } else if (item.report_type === 'combo' && item?.report_type_1_value) {
        return true;
      } else if (item.report_type === 'single' && item?.value) {
        return true;
      } else if (item.report_type === 'editor' && item?.heading) {
        return true;
      } else {
        return false;
      }
    });
  }
  ngOnInit(): void {
    if (window.location.origin.includes('zdiagnosticslab')) {
      this.showLabTestName = false;
    }
    this.pageDataList.lab_test_details = this.filterDataArray(
      this.pageDataList.lab_test_details,
    );
    let editorList = [];
    let cutoffValueList = [];
    let resultList = [];
    for (let j = 0; j < this.pageDataList.lab_test_details.length; j++) {
      if (
        this.pageDataList.lab_test_details[j].show_cutoff_patient_value === true
      ) {
        cutoffValueList.push(this.pageDataList.lab_test_details[j]);
      } else if (
        this.pageDataList.lab_test_details[j].report_type === 'editor'
      ) {
        editorList.push(this.pageDataList.lab_test_details[j]);
      } else {
        resultList.push(this.pageDataList.lab_test_details[j]);
      }
      if (this.pageDataList.lab_test_details[j].unit) {
        this.isUomShow = true;
      }
      if (
        this.pageDataList.lab_test_details[j].range_comments ||
        this.pageDataList.lab_test_details[j].range
      ) {
        this.isRefShow = true;
      }
    }
    this.editor = this.groupBy(editorList, 'super_heading');
    this.cutoffValue = this.groupBy(cutoffValueList, 'super_heading');
    this.result = this.groupBy(resultList, 'super_heading');
    // console.log('result', this.result);
    // console.log('cutoffValue', this.cutoffValue);
    if (this.result.length > 0) {
      this.showResult = true;
    }
    if (this.cutoffValue.length > 0) {
      this.showSutoffValue = true;
    }
    if (this.editor.length > 0) {
      this.showEditor = true;
    }

    this.historyDates = [];
    if (this.showResult) {
      for (let date of this.result[0][0].history) {
        this.historyDates.push(date.previous_result_verification_time);
      }
    }

    setTimeout(() => {
      console.log('editor', this.editor);
      for (let name = 0; name < this.editor.length; name++) {
        for (let z = 0; z < this.editor[name].length; z++) {
          let id = '#editor' + this.groupIndex + '' + name + '' + z;
          // document.getElementById(id).innerHTML = this.editor[name][z].heading;
          $(id).html(this.editor[name][z].heading);
        }
      }
    }, 1500);
    this.instructionDetails = this.pageDataList?.lab_instruction_details;
  }
  // groupBy(array: any, key: any) {
  //   return array.reduce((result, currentValue) => {
  //      let keyValue = currentValue[key];
  //      if (keyValue === null || keyValue === '') {
  //        keyValue = 'null';
  //      }
  //      result[keyValue] = result[keyValue] || [];
  //      result[keyValue].push(currentValue);
  //      return result;
  //   }, {});
  // }
  groupBy(array, key) {
    const grouped = {};
    array.forEach((item) => {
      const keyValue = item[key] || 'null';
      if (!grouped[keyValue]) {
        grouped[keyValue] = [];
      }
      grouped[keyValue].push(item);
    });
    const result = Object.keys(grouped).map((key) => grouped[key]);
    return result;
  }
  convertToNumber(value): any {
    return Number(value);
  }
}
