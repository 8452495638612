import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { id } from 'date-fns/locale';

@Injectable()
export class StoreManagementService {
  constructor(private http: HttpClient) {}

  private currentStatusIR = new BehaviorSubject('');
  currentIRstatus = this.currentStatusIR.asObservable();

  private currentStatusPR = new BehaviorSubject('');
  currentPRstatus = this.currentStatusPR.asObservable();

  private currentStatusPO = new BehaviorSubject('');
  currentPOstatus = this.currentStatusPO.asObservable();

  currentStateOfIR(value): any {
    this.currentStatusIR.next(value);
  }

  currentStateOfPR(value): any {
    this.currentStatusPR.next(value);
  }

  currentStateOfPO(value): any {
    this.currentStatusPO.next(value);
  }

  getStoreListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_store?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  profitLossListing(page, perPage, from_date, to_date, store): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/report/pos_stock_profit_loss_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&from_date=' +
          from_date +
          '&to_date=' +
          to_date +
          '&store=' +
          store,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchPurchase(from_date, to_date, type, supplier): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/category_wise_stock_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          from_date +
          '&to_date=' +
          to_date +
          '&type=' +
          type +
          '&supplier=' +
          supplier,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchPatient(search_string, searchProductType): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/booking/client_search/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          search_string +
          '&search_type=' +
          searchProductType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchDepartmentLocationWise(
    fromDate,
    toDate,
    department,
    location,
    product,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/location_wise_issuance_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate +
          '&department=' +
          department +
          '&location=' +
          location +
          '&product=' +
          product,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchSupplier(from_date, to_date, type, supplier): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/vendor_wise_purchase_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          from_date +
          '&to_date=' +
          to_date +
          '&type=' +
          type +
          '&supplier=' +
          supplier,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchIssue(from_date, to_date, type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/category_wise_issuance_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          from_date +
          '&to_date=' +
          to_date +
          '&type=' +
          type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getCategoryWiseProduct(type, store, showProduct): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/product_category_wise_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          type +
          '&store=' +
          store +
          '&show_product=' +
          showProduct,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getStoreWiseProducts(storeId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_all_store_products?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&store_id=' +
          storeId,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addStore(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/get_store?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateStore(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/get_store_detail?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getSpecificStore(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_store_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchList(
    startDate,
    endDate,
    type,
    store,
    itemName,
    transactionType,
    transactionDescription,
    mrn,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/stock_in_stock_out_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          startDate +
          '&to_date=' +
          endDate +
          '&type=' +
          type +
          '&store=' +
          store +
          '&product=' +
          itemName +
          '&transaction_type=' +
          transactionType +
          '&transaction_description=' +
          transactionDescription +
          '&mrn=' +
          mrn,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  search(
    startDate,
    endDate,
    type,
    store,
    itemName,
    transactionType,
    transactionDescription,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/items_wise_stock_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          startDate +
          '&to_date=' +
          endDate +
          '&type=' +
          type +
          '&store=' +
          store +
          '&product=' +
          itemName +
          '&transaction_type=' +
          transactionType +
          '&transaction_description=' +
          transactionDescription,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  removeStore(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/send_outs?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getStoreTypes(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_store_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getExpiryModule(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/notify_pending_dues?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getStoreCategories(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_store_category?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getParentStoreList(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_store_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getHoldedPosIDs(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/pharmacy/hold_pos_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getHoldedPosDetail(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/pharmacy/hold_pos_data_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getlineItems(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/pharmacy/get_line_items?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getStoreProducts(
    id,
    search,
    page,
    perPage,
    lineItem,
    isZero,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/products_par_level?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&store=' +
          id +
          '&search_string=' +
          search +
          '&line_item=' +
          lineItem +
          '&is_zero=' +
          isZero,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateStoreProducts(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/products_par_level?store=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProducts(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_product_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProductsAgainstSupplier(supplier): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_product_for_ind_po?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&supplier=' +
          supplier,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // API for Products, Store Wise
  getProductsStoreWise(store_id, search_string): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_store_product_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&store_id=' +
          store_id +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // API for Products, POS
  getProductsPOS(search_string): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_pos_store_product?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProductsList(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_product_list_for_dropdown?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProductsMainListBySearch(search_string): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_product_testing?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // For product list in inventory reports dropdown i.e: Current Stock, Ledger Report
  getProductsForDropdown(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_product_list_for_dropdown?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addIndent(
    store,
    demandType,
    demandPriority,
    location,
    value,
    remarks,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/indent_request_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          store,
          demand_type: demandType,
          demand_priority: demandPriority,
          location,
          remarks,
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateIndentRequest(
    store,
    demandType,
    demandPriority,
    location,
    value,
    remarks,
    id,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/get_indent_request_detail_for_update?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          store,
          demand_type: demandType,
          demand_priority: demandPriority,
          location,
          remarks,
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProductsLastDemand(value, location_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/last_demanded_qty?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&location_id=' +
          location_id,
        {
          value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIndent(
    start_date,
    end_date,
    value,
    page,
    per_page,
    department,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_indent_request_for_approval?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&search_string=' +
          value +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addIndentTemplate(
    store,
    demand_type,
    demand_priority,
    location,
    name,
    value,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/indent_template_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name,
          value,
          store,
          demand_type,
          demand_priority,
          location,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateIndentTemplate(
    store,
    demand_type,
    demand_priority,
    location,
    name,
    value,
    id,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/indent_template_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&template_id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name,
          value,
          store,
          demand_type,
          demand_priority,
          location,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIndentTemplate(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/indent_template_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getSpecificIndent(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/indent_request_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIndentRequestDetailForPr(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_indent_request_detail_for_pr?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  mySuggestionProducts(id, store): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/my_suggestion_for_item_on_pr?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&items=' +
          id +
          '&store=' +
          store,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateIndent(value, id, approved, comments): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/change_status_indent_request?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          comments,
          approval_status: approved,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchINNumber(search_string, page, per_page, showHoldIR): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_indent_request?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          search_string +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
        '&is_hold=' + showHoldIR,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIRRequestForPR(
    search_string,
    page,
    per_page,
    store,
    from_date,
    to_date,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_indent_request_for_pr?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          search_string +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&store=' +
          store +
          '&from_date=' +
          from_date +
          '&to_date=' +
          to_date,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getInDatafromid(id, status): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_indent_request_for_issue_notes?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getWarehouseTransferDatafromid(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_warehouse_transfer_for_issue_notes?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitIssueNote(value, remarks, store, isHold): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/issue_note_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          remarks,
          store,
          is_hold: isHold,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // submitPurchaseRequest(store, from_date, to_date, reference_no, demandType, demandPriority, user_name, value, remarks, status, file): Observable<any> {
  //   const body = new FormData();
  //   if (file) {
  //     file.forEach((item: string | Blob) => body.append('file', item));
  //     console.log('body', body);
  //     console.log('file', file);
  //   }
  //   const results = this.http.post(`${environment.pythonUrl}/inventory/purchase_request_list?auth_token=` + localStorage.getItem('auth_token'),body, {
  //     medical_unit_id: localStorage.getItem('current_medical_unit_id'),
  //     store,
  //     from_date,
  //     to_date,
  //     reference_no,
  //     demand_type: demandType,
  //     demand_priority: demandPriority,
  //     user_name,
  //     value,
  //     remarks,
  //     status,
  //   }, {headers: new HttpHeaders({
  //       Accept: 'application/json'
  //     })}).pipe(map(data => data));
  //   return results;
  // }
  submitPurchaseRequest(
    store,
    from_date,
    to_date,
    reference_no,
    demandType,
    demandPriority,
    user_name,
    value,
    remarks,
    status,
    location,
  ): Observable<any> {
    const results = this.http.post(
      `${environment.pythonUrl}/inventory/purchase_request_list?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      {
        medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        store,
        from_date,
        to_date,
        reference_no,
        demand_type: demandType,
        demand_priority: demandPriority,
        user_name,
        value,
        remarks,
        status,
        location,
      },
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }

  updatePurchaseRequest(
    store,
    from_date,
    to_date,
    reference_no,
    demandType,
    demandPriority,
    user_name,
    value,
    remarks,
    status,
    id,
  ): Observable<any> {
    const results = this.http.put(
      `${environment.pythonUrl}/inventory/get_pr_detail_for_update?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&id=' +
        id,
      {
        medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        store,
        from_date,
        to_date,
        reference_no,
        demand_type: demandType,
        demand_priority: demandPriority,
        user_name,
        value,
        remarks,
        status,
      },
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }

  submitPurchaseRequestDocs(file, id): Observable<any> {
    const body = new FormData();
    if (file) {
      file.forEach((item: string | Blob) => body.append('file', item));
    }
    body.append('id', id);
    const results = this.http.post(
      `${environment.pythonUrl}/inventory/upload_pr_files?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }

  submitDoc(old_docs, file, id): Observable<any> {
    const body = new FormData();
    if (file) {
      file.forEach((item: string | Blob) => body.append('file', item));
    }
    body.append('old_file', JSON.stringify(old_docs));
    // body.append('id', id);
    const results = this.http.post(
      `${environment.pythonUrl}/inventory/upload_pr_files_for_update?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&id=' +
        id,
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }

  addPrTemplate(name, response): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/purchase_request_template?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name,
          response: response.indent_list,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPrIndentTemplate(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/purchase_request_template_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIRListing(
    search_string,
    start_date,
    end_date,
    status,
    page,
    per_page,
    showAllRecords,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_indent_request_for_main_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          search_string +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&status=' +
          status +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&show_all_records=' +
          showAllRecords,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getINListing(start_date, end_date, page, per_page): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/issue_note_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPrTemplates(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/purchase_request_template?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPrMainListing(
    search_string,
    page,
    perPage,
    startDate,
    endDate,
    value,
    showAllRecords,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/purchase_request_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          search_string +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&start_date=' +
          startDate +
          '&end_date=' +
          endDate +
          '&status=' +
          value +
          '&show_all_records=' +
          showAllRecords,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getVendorListing(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_vendor_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Active Vendor list API name changed as per Sir Kashif
  getActiveVendorListing(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/active_vendor_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPrDetailFromId(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_purchase_request_for_purchase_order?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getTaxListingWithOutPagination(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/tax_type_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitPurchaseOrder(value, remarksObj): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/purchase_order_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          remarks_obj: remarksObj,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitIndependentPurchaseOrder(value, remarksObj): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/independent_purchase_order?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          remarks_obj: remarksObj,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  upadtePurchaseOrder(value, remarksObj, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/get_po_detail_for_update?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          remarks_obj: remarksObj,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPoMainListing(
    page,
    perPage,
    search_string,
    vendor,
    department,
    showAllRecords,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/purchase_order_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          search_string +
          '&vendor=' +
          vendor +
          '&department=' +
          department +
          '&show_all_records=' +
          showAllRecords,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPurchaseOrderListWithOutPagination(vendor): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/purchase_order_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&vendor=' +
          vendor,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  purchaseOrderListForGrn(vendor): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/purchase_order_list_for_grn?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&vendor=' +
          vendor,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPurchaseOrderItemsDetails(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/purchase_order_detail_for_grn?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getGrnDetailsById(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/good_receiving_notes_update?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateGRN(editId, value, remarksObj): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/good_receiving_notes_update?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          id: editId,
          value,
          remarks_obj: remarksObj,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPurchaseOrderItemsDetailsNew(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/purchase_order_report_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPurchaseOrderItemsDetailsByName(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/purchase_order_detail_for_approval_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitGrnOrder(value, remarksObj): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/good_receiving_notes_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          remarks_obj: remarksObj,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitStoreReportingFlowPolicyForm(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/abc?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateStoreReportingFlowPolicyForm(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/abc?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitWarehouseTransfer(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/warehouse_transfer_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getWarehouseTransferListing(
    page,
    perPage,
    startDate,
    endDate,
    search_string,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/warehouse_transfer_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&start_date=' +
          startDate +
          '&end_date=' +
          endDate +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getWarehouseTransferListingWithOutPagination(search_string): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/warehouse_transfer_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitIssueNoteForWt(value, remarks): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/warehouse_transfer_issue_note_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          remarks,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  issueNotesReturnList(searchString, type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/issue_note_list_for_return?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          searchString +
          '&type=' +
          type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIssueNotes(current_page, per_page, searchString, status): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/work_flow_policy_type_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          searchString +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIssueNoteIrDetail(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/issue_notes_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitIssueNotesReturnList(value, remarks): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/issue_notes_return_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          remarks,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getINReturnListing(
    startDate,
    endDate,
    searchString,
    page,
    perPage,
    approvalStatus,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/issue_notes_return_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&start_date=' +
          startDate +
          '&end_date=' +
          endDate +
          '&search_string=' +
          searchString +
          '&approval_status=' +
          approvalStatus,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getGrnList(
    searchGrnNo,
    searchInvoiceNo,
    store,
    start_date,
    end_date,
    page,
    per_page,
    approvalStatus,
    vendor,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/good_receiving_notes_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&grn_no=' +
          searchGrnNo +
          '&invoice_no=' +
          searchInvoiceNo +
          '&store=' +
          store +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&approval_status=' +
          approvalStatus +
          '&vendor=' +
          vendor,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  verifyGrn(id, approvalStatus): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/verify_grn?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          id,
          approval_status: approvalStatus,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  verifyIssueNote(id, approvalStatus): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/verify_issue_note?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          id,
          status: approvalStatus,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  verifyIssueNoteReturn(id, approvalStatus): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/verify_issue_note_return?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          id,
          approval_status: approvalStatus,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  grnList(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/good_receiving_notes_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getGrnDetail(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_grn_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitGrnReturnList(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/grn_return_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getGrnReturnList(start_date, end_date, page, per_page): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/grn_return_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIRListingById(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_indent_request_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIRDetail(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_indent_request_detail_for_update?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPRDetail(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_pr_detail_for_update?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPoDetail(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_po_detail_for_update?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  cancelRequestIR(id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/cancel_indent_request?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  checkViewOfIssueNoteReturn(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/issue_notes_return_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  checkViewOfWarehouseTransfer(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_warehouse_transfer_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPurchaseRequestApprovalListing(
    value,
    page,
    per_page,
    department,
    canSeePrOfOther,
    status,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_purchase_request_for_approval?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          value +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&canSeePrOfOther=' +
          canSeePrOfOther +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // IR List for Inventory List Dashboard New with kamran
  getIntentRequestListingDashboard(
    start_date,
    end_date,
    doc_type,
    page,
    per_page,
    department,
    status,
    ref_no,
    show_flow_wise,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_indent_request_for_dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&doc_type=' +
          doc_type +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&status=' +
          status +
          '&ref_no=' +
          ref_no +
          '&show_flow_wise=' +
          show_flow_wise,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // PR List for Inventory List Dashboard New with kamran
  getPurchaseRequestListingDashboard(
    start_date,
    end_date,
    docType,
    page,
    per_page,
    department,
    status,
    ref_no,
    show_flow_wise,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_purchase_request_for_dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&doc_type=' +
          docType +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&status=' +
          status +
          '&ref_no=' +
          ref_no +
          '&show_flow_wise=' +
          show_flow_wise,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // PO List for Inventory List Dashboard New with kamran
  getPurchaseOrderListingDashboard(
    start_date,
    end_date,
    doc_type,
    page,
    per_page,
    department,
    status,
    ref_no,
    show_flow_wise,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_purchase_order_for_dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&doc_type=' +
          doc_type +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&status=' +
          status +
          '&ref_no=' +
          ref_no +
          '&show_flow_wise=' +
          show_flow_wise,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // API for IN Listing Dashboard
  getIssuesNotesApprovedListingDashboard(
    start_date,
    end_date,
    doc_type,
    page,
    per_page,
    department,
    status,
    ref_no,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/issue_note_for_dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&doc_type=' +
          doc_type +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&status=' +
          status +
          '&ref_no=' +
          ref_no,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // API for IN Listing Dashboard
  getGRNApprovedListingDashboard(
    start_date,
    end_date,
    doc_type,
    page,
    per_page,
    department,
    status,
    ref_no,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/good_receiving_notes_list_for_dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&doc_type=' +
          doc_type +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&status=' +
          status +
          '&ref_no=' +
          ref_no,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPurchaseOrderApprovalListing(
    value,
    page,
    per_page,
    department,
    vendor,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_purchase_order_for_approval?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          value +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&vendor=' +
          vendor,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // api for
  getAttendanceData(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_employee_attendance?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPurchaseRequestApprovalById(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/purchase_request_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  approvePurchaseRequest(value, id, approved, comments): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/change_status_purchase_request?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          product_list: value,
          comments,
          approval_status: approved,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  approvePurchaseOrder(
    value,
    id,
    approved,
    comments,
    canEdit,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/change_status_purchase_order?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          comments,
          approval_status: approved,
          can_edit: canEdit,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIssuesNotesApprovedListing(
    searchString,
    searchStringIR,
    department,
    store,
    page,
    perPage,
    searchStringLocation,
    start_date,
    end_date,
    showAllRecords,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/issue_note_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          searchString +
          '&search_string_ir=' +
          searchStringIR +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&department=' +
          department +
          '&store=' +
          store +
          '&search_location=' +
          searchStringLocation +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&show_all_records=' +
          showAllRecords,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getWarehouseTransferApprovedListing(
    searchString,
    page,
    perPage,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/warehouse_transfer_issue_note_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          searchString +
          '&page=' +
          page +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIssueNoteById(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/issue_notes_approved_by_id?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  selectMainStore(id, is_main): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/update_for_main_store?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          is_main,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getWHIssueNoteById(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/warehouse_transfer_issue_note_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  cancelStatusPurchaseOrder(id, approval_status): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/purchase_order_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          approval_status,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  cancelRestorePR(id, approval_status): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/purchase_request_change_status?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          approval_status,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIRHistoryRecord(
    page,
    per_page,
    department,
    search_string,
    filter,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_ir_history?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&search_string=' +
          search_string +
          '&status=' +
          filter,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIRReview(
    page,
    per_page,
    department,
    search_string,
    filter,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_indent_request_for_review?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&search_string=' +
          search_string +
          '&status=' +
          filter,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPRHistoryRecord(
    page,
    per_page,
    department,
    search_string,
    filter,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_pr_history?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&search_string=' +
          search_string +
          '&status=' +
          filter,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPOHistoryRecord(
    page,
    per_page,
    filter,
    search_string,
    department,
    vendor,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_po_history?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&search_string=' +
          search_string +
          '&status=' +
          filter +
          '&vendor=' +
          vendor,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPreviousProductRateAndDate(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_product_detail_for_grn?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProductsAgainstLineItem(type: any): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_product_against_line_item?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          type,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addImageInPo(file, id): Observable<any> {
    const body = new FormData();
    if (file) {
      file.forEach((item: string | Blob) => body.append('file', item));
    }
    const results = this.http.post(
      `${environment.pythonUrl}/inventory/upload_purchase_order_image` +
        `?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&id=' +
        id,
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }

  submitInvoice(invoicing, store, customer): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/product_variant_invoice?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          invoicing,
          store,
          customer,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchVariant(store, product): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_variant_for_invoicing?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&store=' +
          store +
          '&product=' +
          product,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getInvoiceData(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/invoice_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getStoreTypeData(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/user_store/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&user_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // getUserPaymentTypes(id): Observable<any> {
  //   const results = this.http.get(`${environment.pythonUrl}/api/any/` + id + `/?auth_token=` + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&user_id=' + id
  //     , { headers: new HttpHeaders({
  //         AuthenticationToken: localStorage.getItem('auth_token'),
  //         Accept: 'application/json'
  //       })}).pipe(map(data => data));
  //   return results;
  // }
  updateUserStoreType(id, store): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/user_store/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          user: id,
          store,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateUserLineItemOrShowMedicineIssuanceItem(
    id,
    type,
    showItemOnMedicineIssuance,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/user_inventory_setting?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          user: id,
          line_item: type,
          show_item_on_medicine_issuance: showItemOnMedicineIssuance,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatePaymentTypes(
    id,
    payment_type_unpaid,
    disabled_payment_type,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/any/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          user: id,
          payment_type_unpaid: payment_type_unpaid,
          disabled_payment_type: disabled_payment_type,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIndependentGrnSearchProducts(searchValue): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_product_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          searchValue,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProductAccordingToUrl(searchValue, url, supplier): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}` +
          url +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          searchValue +
          '&supplier=' +
          supplier,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProductsForPR(storeId, searchValue): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_product_list_for_pr?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&store=' +
          storeId +
          '&search_string=' +
          searchValue,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProductsForLabTest(searchValue): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_product_listing_for_cost_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          searchValue,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProductsOfPatient(mrn): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_opd_pos_store_product?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_mrn=' +
          mrn,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPrActiveListing(department): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_purchase_request?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
