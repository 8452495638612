<div>
  <div class="card card-custom gutter-b">
    <div
      class="card-header card-header-tabs-line notes-card-header"
      *ngIf="isAdd === true"
    >
      <div class="card-toolbar notes-toolbar">
        <ul class="nav nav-tabs nav-bold nav-tabs-line">
          <li class="nav-item notes-li" (click)="changeTab()">
            <a class="nav-link active" data-toggle="tab" href="#dosetype">
              <span class="nav-text">Medicine </span>
            </a>
          </li>
          <li
            class="nav-item notes-li"
            *ngIf="type === 'IPD'"
            (click)="goToConsume()"
          >
            <a class="nav-link" data-toggle="tab" href="#is_consume">
              <span class="nav-text">Consumable</span>
            </a>
          </li>
          <li
            class="nav-item notes-li"
            *ngIf="type === 'IPD' || type === 'Emergency'"
          >
            <a
              class="nav-link"
              data-toggle="tab"
              href="#dosetime"
              (click)="
                getMedicineAdministration(''); getDateMedicine(); changeTab()
              "
            >
              <span class="nav-text">Administration</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="card-body user-card-body">
      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          id="dosetype"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_1_4"
        >
          <!--TAB id= dosetime :: 1st card-->
          <div class="card mb-4" *ngIf="isAdd === true">
            <div class="card-body user-card-body">
              <div class="d-block d-sm-block d-md-none d-lg-none">
                <div
                  class="row mb-2"
                  *ngFor="let medicine of addMedicine; let i = index"
                >
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label>Dose Type<span class="text-danger">*</span></label>
                      <select
                        class="form-control form-control-sm"
                        id="stockTypes{{ i }}"
                        [(ngModel)]="medicine.stoc_type_id"
                        (change)="updateDoses($event.target.value, i)"
                      >
                        <option value="">Select</option>
                        <option
                          *ngFor="let data of doseTypeList"
                          [value]="data.id"
                        >
                          {{ data?.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    *ngIf="!isipd"
                    class="col-sm-6 col-12"
                    id="medicineSearchMain{{ i }}"
                  >
                    <div class="form-group">
                      <label
                        >Medicines <span class="text-danger">*</span></label
                      >
                      <input
                        class="form-control form-control-sm"
                        placeholder="Search Medicine"
                        id="medicineNames{{ i }}"
                        [(ngModel)]="medicine.name"
                        type="text"
                        tabindex="-1"
                        (keyup)="getProductListMain(i)"
                        (click)="getProductListMain(i)"
                      />
                      <div
                        class="search-result-main"
                        *ngIf="medicine.name.length > 2"
                      >
                        <p
                          *ngIf="
                            productListData.length === 0 &&
                            productSearchResult === false
                          "
                        >
                          Searching...
                        </p>
                        <p
                          *ngIf="
                            productListData.length === 0 &&
                            productSearchResult === true
                          "
                        >
                          No Result Found
                        </p>
                        <span *ngIf="productListData.length > 0">
                          <p
                            *ngFor="let product of productListData"
                            (click)="setProductMain(product, i)"
                          >
                            {{ product.product_name }}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label>Dose <span class="text-danger">*</span></label>
                      <select
                        class="form-control form-control-sm"
                        [(ngModel)]="medicine.dosee_id"
                      >
                        <option value="">Select</option>
                        <option
                          *ngFor="let data of frequencyList"
                          [value]="data.id"
                        >
                          {{ data?.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label>Days</label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        min="0"
                        placeholder="days"
                        id="medicineNoOfDayss{{ i }}"
                        [(ngModel)]="medicine.no_of_days"
                      />
                    </div>
                    <!--                        (keyup)="updateMedicineToDate(i)"-->
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label>Type <span class="text-danger">*</span></label>
                      <select
                        class="form-control form-control-sm"
                        id="doseTypes{{ i }}"
                        [(ngModel)]="medicine.dos_type_id"
                      >
                        <option value="">Select</option>
                        <option *ngFor="let data of doses[i]" [value]="data.id">
                          {{ data?.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="form-group">
                      <label>Comments</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Comments"
                        [(ngModel)]="medicine.comments"
                      />
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      type="button"
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                      *ngIf="addMedicine.length !== 1"
                      (click)="removeMedicine(i)"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                        <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                            />
                            <path
                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row m-0">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                  <div class="form-group">
                    <label>Advised By <span class="text-danger">*</span></label>
                    <select
                      class="form-control form-control-sm referringDoctors bootstrap-select-container"
                      [(ngModel)]="advisedBy"
                      (change)="seperateAdvisedBy($event.target.value)"
                      data-live-search="true"
                      data-container="body"
                    >
                      <option [disabled]="true" value="">Select</option>
                      <option
                        *ngFor="let doc of allActiveEmployees"
                        [value]="doc.id + '/' + doc.employee_type"
                      >
                        {{ doc.full_name }}
                        <span *ngIf="doc.department_name">
                          ({{ doc.department_name }})</span
                        >
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                class="d-none d-sm-none d-md-block d-lg-block"
                style="position: static; padding-left: 0; padding-right: 0"
              >
                <div class="row m-0">
                  <div class="col-md-4 col-sm-6 col-12">
                    <label
                      >Name of Medicine
                      <span class="text-danger">*</span></label
                    >
                  </div>
                  <div class="col-md-2 col-sm-6 col-12 pl-0">
                    <label>Dose Type <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-2 col-sm-6 col-12 pl-0">
                    <label>Frequency <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-2 col-sm-6 col-12 pl-0">
                    <label>Dose</label>
                  </div>
                  <div class="col-md-1 col-sm-6 col-12 pl-0">
                    <label>Route</label>
                  </div>
                </div>
                <div
                  class="row mx-0 mt-1 mb-4"
                  *ngFor="let medicine of addMedicine; let i = index"
                >
                  <div
                    class="col-md-4 col-sm-6 col-12"
                    id="medicineSearchMain{{ i }}"
                  >
                    <div class="input-group">
                      <input
                        class="form-control form-control-sm"
                        placeholder="Search Medicine"
                        autocomplete="off"
                        id="medicineName{{ i }}"
                        [(ngModel)]="medicine.name"
                        [disabled]="medicine.item_id !== ''"
                        type="text"
                        (keyup)="getMedicinesOrProductList(i)"
                        (click)="getMedicinesOrProductList(i)"
                        (focusout)="focusOut(i)"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text px-2 py-0">
                          <i
                            class="quick-search-close ki ki-close icon-sm cursor-pointer"
                            (click)="removeSearchString(i)"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div
                      class="search-result-main"
                      *ngIf="medicine.name.length > 2"
                    >
                      <p
                        *ngIf="
                          productListData.length === 0 &&
                          productSearchResult === false
                        "
                      >
                        Searching...
                      </p>
                      <p
                        *ngIf="
                          productListData.length === 0 &&
                          productSearchResult === true
                        "
                      >
                        No Result Found
                      </p>
                      <span *ngIf="productListData.length > 0">
                        <p
                          *ngFor="let product of productListData"
                          (click)="setProductMain(product, i)"
                        >
                          {{ product.product_name }}
                        </p>
                      </span>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-6 col-12 pl-0">
                    <select
                      class="form-control form-control-sm"
                      autocomplete="off"
                      id="stockType{{ i }}"
                      [(ngModel)]="medicine.stoc_type_id"
                      (change)="updateDoses($event.target.value, i)"
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let data of doseTypeList"
                        [value]="data.id"
                      >
                        {{ data?.name }}
                      </option>
                    </select>
                    <!--                        <input type="date"  class="form-control form-control-sm" id="medicineStart{{i}}" [(ngModel)]="medicine.from_date">-->
                  </div>

                  <div class="col-md-2 col-sm-6 col-12 pl-0">
                    <select
                      class="form-control form-control-sm"
                      autocomplete="off"
                      id="frequencyDose{{ i }}"
                      [(ngModel)]="medicine.dosee_id"
                    >
                      <option value="">Select</option>
                      <!--                                  <option *ngFor="let data of frequencyList" [value]="data.id">{{data?.name}}</option>-->
                      <option
                        *ngFor="let data of frequencyList"
                        [value]="data.id"
                      >
                        {{ data?.name }}
                      </option>
                      <option value="npo">Npo</option>
                    </select>
                  </div>

                  <div class="col-md-2 col-sm-6 col-12 pl-0">
                    <select
                      class="form-control form-control-sm"
                      autocomplete="off"
                      (change)="addDoseQty(i)"
                      id="doseType{{ i }}"
                      [(ngModel)]="medicine.dos_type_id"
                    >
                      <option value="">Select</option>
                      <option *ngFor="let data of doses[i]" [value]="data.id">
                        {{ data?.name }}
                      </option>
                      <option [ngValue]="-1">Other</option>
                    </select>
                  </div>
                  <div class="col-md-1 col-sm-6 col-12 pl-0">
                    <select
                      class="form-control form-control-sm"
                      autocomplete="off"
                      id="routeId{{ i }}"
                      [(ngModel)]="medicine.routee_id"
                    >
                      <option value="" disabled>Route</option>
                      <option *ngFor="let data of routeList" [value]="data.id">
                        {{ data?.name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-1 col-sm-6 col-12 pl-0">
                    <button
                      type="button"
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                      *ngIf="addMedicine.length !== 1"
                      (click)="removeMedicine(i)"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                        <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                            />
                            <path
                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </button>
                  </div>
                  <div class="col-md-2 col-sm-6 col-12 mt-2">
                    <input
                      type="date"
                      [min]="today"
                      class="form-control form-control-sm"
                      autocomplete="off"
                      id="medicineStart{{ i }}"
                      (change)="updateMedicineToDate(i)"
                      (change)="updateMedicineToDurationDays(i)"
                      [(ngModel)]="medicine.from_date"
                    />
                  </div>
                  <div class="col-md-2 col-sm-6 col-12 pl-0">
                    <input
                      type="number"
                      (keyup)="updateMedicineToDate(i)"
                      autocomplete="off"
                      class="form-control form-control-sm"
                      min="0"
                      placeholder="days"
                      id="medicineNoOfDays{{ i }}"
                      [(ngModel)]="medicine.no_of_days"
                    />
                  </div>

                  <div class="col-md-2 col-sm-6 col-12 pl-0 mt-2">
                    <!--                                <label>End Date <span class="text-danger">*</span></label>-->
                    <input
                      type="date"
                      [min]="today"
                      class="form-control form-control-sm"
                      id="medicineEnd{{ i }}"
                      autocomplete="off"
                      (change)="updateMedicineToDurationDays(i)"
                      [(ngModel)]="medicine.to_date"
                    />
                  </div>
                  <div
                    class="col-md-2 col-sm-2 col-12 pl-0 mt-2"
                    *ngIf="medicine?.dos_type_id === -1"
                  >
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="doseQty{{ i }}"
                      placeholder="quantity"
                      autocomplete="off"
                      [(ngModel)]="medicine.dose_other_quantity"
                    />
                  </div>
                  <div
                    class="pl-0 mt-2"
                    [ngClass]="{
                      'col-md-5 col-sm-6 col-12 ': medicine?.dos_type_id !== -1,
                      'col-md-3 col-sm-4 col-12 ': medicine?.dos_type_id === -1
                    }"
                  >
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Comments"
                      autocomplete="off"
                      [(ngModel)]="medicine.comments"
                    />
                  </div>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-12 text-right pr-5">
                  <button
                    type="button"
                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                    (click)="addMoreMedicine()"
                  >
                    <span class="svg-icon svg-icon-primary svg-icon-2x">
                      <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                          <path
                            d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                            fill="#000000"
                          />
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    (click)="addMedicineAgainstPatient('submit')"
                  >
                    Submit
                  </button>
                  <!--                        <button class="btn btn-primary btn-sm ml-2" (click)="moveNextTab('ServicesTabs')">Next</button>-->
                  <!--                        <button class="btn btn-primary btn-sm ml-2" (click)="addMedicineAgainstPatient('next')">Submit & Next</button>-->
                </div>
              </div>
            </div>
          </div>

          <hr *ngIf="isAdd === true" />

          <!--TAB id= dosetime :: 2nd card-->
          <div class="card">
            <div class="card-body user-card-body">
              <div class="row m-0">
                <div class="col-md-4 col-sm-4 col-12">
                  <select
                    *ngIf="isAdd === true"
                    class="form-control form-control-sm"
                    [(ngModel)]="servicesPrintRecent"
                    (change)="updateServicesPrintRecent($event.target.value)"
                  >
                    <option value="false">Previous</option>
                    <option value="true">Recent</option>
                  </select>
                </div>
                <div class="col-md-4 col-sm-4 col-12"></div>
                <div
                  class="col-md-4 col-sm-4 col-12 text-right prespirationChartr-0 mt-3 mt-sm-0 mt-md-0"
                >
                  <button
                    *ngIf="isAdd === true"
                    class="btn btn-primary btn-sm mr-2"
                    (click)="returnMedicines()"
                  >
                    Return Medicine
                  </button>
                  <button
                    *ngIf="isAdd === true || isAdd === false"
                    class="btn btn-primary btn-sm"
                    (click)="printRecentMedicineSlip()"
                  >
                    Print
                  </button>
                </div>
              </div>
              <div class="w-100">
                <table
                  class="tableMedicine table-head-custom table-vertical-center"
                >
                  <thead>
                    <tr>
                      <th
                        style="width: 5%"
                        *ngIf="isAdd === true || isAdd === false"
                      >
                        <label class="checkbox checkbox-sm">
                          <input
                            type="checkbox"
                            class="recentMedicineAll"
                            id="recentMedicineAll"
                            (change)="selectAllRecentMedicine()"
                          />
                          <span></span>
                        </label>
                      </th>
                      <th style="width: 15%" class="text-muted">
                        Requested Date
                      </th>
                      <th style="width: 23%" class="text-muted">Name / Type</th>
                      <th style="width: 15%" class="text-muted">
                        Frequency / Dose
                      </th>
                      <th style="width: 10%" class="text-muted">Route</th>
                      <th style="width: 15%" class="text-muted">
                        Start / End Date
                      </th>
                      <th style="width: 7%" class="text-muted">Days</th>
                      <th style="width: 5%" class="text-muted">Status</th>
                      <th
                        style="width: 5%; text-align: right"
                        class="text-muted"
                        *ngIf="isAdd === true"
                      >
                        Action
                      </th>
                      <!--                    <th style="width: 10%">End Date</th>-->
                      <!--                    <th style="width: 10%">Dose Type</th>-->
                      <!--                    <th style="width: 10%">Frequency</th>-->
                    </tr>
                  </thead>
                  <tbody *ngIf="paitentMedicineHistory.length > 0">
                    <ng-container
                      *ngFor="
                        let history of paitentMedicineHistory;
                        let i = index
                      "
                    >
                      <ng-container
                        *ngIf="
                          history.recent === servicesPrintRecent ||
                          isAdd === false
                        "
                      >
                        <tr style="border-top: 1px solid #ebedf3 !important">
                          <td style="width: 5%">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                class="recentMedicine"
                                id="recientMedicine{{ i }}"
                              />
                              <span></span>
                            </label>
                          </td>
                          <td style="width: 15%">
                            <span class="d-block">{{
                              history.created_at | date: "dd-MM-yyyy"
                            }}</span>
                            <span class=" ">{{
                              history.created_at | date: "h:mm a"
                            }}</span>
                          </td>
                          <td style="width: 23%">
                            <span class="d-block">{{
                              history.item.product_name
                            }}</span>
                            <span class=" ">{{
                              history.stoc_type | titlecase
                            }}</span>
                          </td>
                          <td style="width: 15%">
                            <span class="d-block">{{ history.dosee }}</span>
                            <span class=" ">{{
                              history.dos_type || history?.dose_other_quantity
                                | uppercase
                            }}</span>
                          </td>
                          <td style="width: 10%">{{ history.routee }}</td>
                          <td style="width: 15%">
                            <span class="d-block">{{
                              history?.from_date | date: "dd-MM-yyyy"
                            }}</span>
                            <span class=" ">{{
                              history?.to_date | date: "dd-MM-yyyy"
                            }}</span>
                          </td>
                          <!--                        <td style="width: 10%">{{history.to_date | date: 'dd-MM-yyyy'}}</td>-->
                          <td style="width: 7%">{{ history.no_of_days }}</td>
                          <td style="width: 5%">
                            <span
                              class="label label-lg label-light-primary label-inline"
                              *ngIf="history?.status === 'Open'"
                              >Requested</span
                            >
                            <span
                              class="label label-lg label-light-info label-inline"
                              *ngIf="history?.status === 'Approved'"
                              >Approved</span
                            >
                            <span
                              class="label label-lg label-light-warning label-inline"
                              *ngIf="history?.status === 'Issued'"
                              >Issued</span
                            >
                            <span
                              class="label label-lg label-light-warning label-inline"
                              *ngIf="history?.status === 'Received'"
                              >Received</span
                            >
                            <span
                              class="label label-lg label-light-warning label-inline"
                              *ngIf="history?.status === 'Partial Issued'"
                              >Partial Issued</span
                            >
                            <span
                              class="label label-lg label-light-danger label-inline"
                              *ngIf="history?.status === 'Rejected'"
                              >Rejected</span
                            >
                            <span
                              class="label label-lg label-light-danger label-inline"
                              *ngIf="history?.status === 'Cancel'"
                              >Cancelled</span
                            >
                            <span
                              class="label label-lg label-light-danger label-inline"
                              *ngIf="history?.status === 'Discontinued'"
                              >Discontinued</span
                            >
                            <!--                          <span class="label  label-lg label-light-danger label-inline" *ngIf="history?.status === 'Discontinued'">Discontinued</span>-->
                          </td>
                          <td
                            style="width: 5%; text-align: right"
                            *ngIf="isAdd === true"
                          >
                            <div class="dropdown dropdown-inline mr-1">
                              <button
                                type="button"
                                class="btn btn-light-primary btn-icon btn-sm"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="ki ki-bold-more-ver"></i>
                              </button>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a
                                  class="dropdown-item cursor-pointer"
                                  *ngIf="history?.status === 'Open'"
                                  (click)="updateMedicines(history)"
                                  >Edit</a
                                >
                                <a
                                  *ngIf="
                                    type === 'IPD' &&
                                    history?.status !== 'Open' &&
                                    history?.status !== 'Approved' && !isIpdMedicineConfigurator?.show_req_med_directly_on_admin
                                  "
                                  class="dropdown-item cursor-pointer"
                                  (click)="returnMedicineModal(history)"
                                  >Return</a
                                >
                                <a
                                  class="dropdown-item cursor-pointer"
                                  *ngIf="history?.status === 'Open'"
                                  (click)="cancelMedicine(history?.id)"
                                  >Cancel</a
                                >
                                <a
                                  class="dropdown-item cursor-pointer"
                                  *ngIf="
                                    history?.status === 'Rejected' && isipd
                                  "
                                  (click)="rejectionReasonModal(history)"
                                  >Rejection Reason</a
                                >
                                <!--                              <a class="dropdown-item cursor-pointer">Details</a>-->
                              </div>
                            </div>
                          </td>
                          <!--                        <td style="width: 10%">{{history.stoc_type | titlecase}}</td>-->
                          <!--                        <td style="width: 10%">{{history?.dosee}}</td>-->
                        </tr>
                        <tr *ngIf="history.comments">
                          <td colspan="10">Comments: {{ history.comments }}</td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="dosetime"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_2_4"
        >
          <div class="card border-0">
            <app-medicine-administration
              [patient]="patient"
              [isAdd] = "isAdd"
              (isSubmitDiscontinuedforListing)="
                getSubmitDiscontinuedforListing($event)
              "
            ></app-medicine-administration>
          </div>
        </div>
        <!--        is_consume-->
        <div
          class="tab-pane fade"
          id="is_consume"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_2_4"
        >
          <div class="card border-0" *ngIf="isConsume">
            <app-is-consumable
              [allActiveEmployees]="allActiveEmployees"
              [patient]="patient"
              [doseTypeList]="doseTypeList"
              [visitType]="visitType"
              [isipd]="isipd"
              [isIpdMedicineConfigurator]="isIpdMedicineConfigurator"
            ></app-is-consumable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--begin::Card-->

  <!--end::Card-->
</div>

<!--Update Medicine Modal start-->
<div
  class="modal fade"
  id="updateMedicine"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Update Medicine</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4 col-sm-6 col-12">
            <label>Name of Medicine <span class="text-danger">*</span></label>
            <input
              class="form-control form-control-sm"
              placeholder="Search Medicine"
              disabled
              [(ngModel)]="medicine"
            />
            <!--                   [(ngModel)]="medicine.name" type="text" (keyup)="getProductListMain(i)"-->
            <!--                   (click)="getProductListMain(i)" (focusout)="focusOut()">-->
            <!--            <div class="search-result-main" *ngIf="medicine.name.length > 2">-->
            <!--              <p *ngIf="productListData.length === 0 && productSearchResult === false">-->
            <!--                Searching...</p>-->
            <!--              <p *ngIf="productListData.length === 0 && productSearchResult === true">No Result-->
            <!--                Found</p>-->
            <!--              <span *ngIf="productListData.length > 0">-->
            <!--                            <p *ngFor="let product of productListData" (click)="setProductMain(product, i)">-->
            <!--                              {{product.product_name}}</p>-->
            <!--                          </span>-->
            <!--            </div>-->
          </div>

          <div class="col-md-2 col-sm-6 col-12 pl-0">
            <label>Dose Type</label>
            <select
              class="form-control form-control-sm"
              disabled
              [(ngModel)]="doseType"
              (change)="updateDosesForSingleMed($event.target.value)"
            >
              <option value="">Select</option>
              <option *ngFor="let data of doseTypeList" [value]="data.id">
                {{ data?.name }}
              </option>
            </select>
            <!--                        <input type="date"  class="form-control form-control-sm" id="medicineStart{{i}}" [(ngModel)]="medicine.from_date">-->
          </div>

          <div class="col-md-2 col-sm-6 col-12 pl-0">
            <label>Frequency <span class="text-danger">*</span></label>
            <select
              class="form-control form-control-sm"
              [(ngModel)]="frequencyy"
            >
              <option value="">Select</option>
              <!--                                  <option *ngFor="let data of frequencyList" [value]="data.id">{{data?.name}}</option>-->
              <option *ngFor="let data of frequencyList" [value]="data.id">
                {{ data?.name }}
              </option>
              <option value="npo">Npo</option>
            </select>
          </div>

          <div class="col-md-2 col-sm-6 col-12 pl-0">
            <label>Dose</label>
            <select
              class="form-control form-control-sm"
              [(ngModel)]="dose"
              (change)="showDoseQty()"
            >
              <option value="">Select</option>
              <option *ngFor="let data of dosesList" [value]="data.id">
                {{ data?.name }}
              </option>
              <option [ngValue]="-1">Other</option>
            </select>
          </div>
          <div class="col-md-2 col-sm-6 col-12 pl-0" *ngIf="dose === -1">
            <label>Quantity</label>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="quantity"
              [(ngModel)]="dose_other_quantity"
            />
          </div>
          <div
            class="col-md-2 col-sm-6 col-12"
            [ngClass]="{ 'mt-2': dose === -1 }"
          >
            <label *ngIf="dose !== -1">Route</label>
            <select class="form-control form-control-sm" [(ngModel)]="routee">
              <option value="" disabled>Route</option>
              <option *ngFor="let data of routeList" [value]="data.id">
                {{ data?.name }}
              </option>
            </select>
          </div>

          <div class="col-md-2 col-sm-6 col-12 mt-2">
            <input
              type="date"
              [min]="today"
              class="form-control form-control-sm"
              id="medicineStart"
              [(ngModel)]="fromDate"
            />
          </div>
          <div class="col-md-2 col-sm-6 col-12 pl-0 mt-2">
            <input
              type="number"
              class="form-control form-control-sm"
              min="0"
              placeholder="days"
              id="medicineNoOfDays"
              (keyup)="updateSingleMedicineToDate()"
              [(ngModel)]="days"
            />
          </div>

          <div class="col-md-2 col-sm-6 col-12 pl-0 mt-2">
            <!--                                <label>End Date <span class="text-danger">*</span></label>-->
            <input
              type="date"
              [min]="today"
              class="form-control form-control-sm"
              [(ngModel)]="toDate"
            />
          </div>
          <div
            class="pl-0 mt-2"
            [ngClass]="{
              'col-md-6 col-sm-6 col-12': dose !== -1,
              'col-md-4 col-sm-4 col-12': dose === -1
            }"
          >
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Pharmacist Comments"
              [(ngModel)]="pharmacistComments"
            />
          </div>
        </div>
        <div class="pl-0 mt-3">
          <p><strong>Doctor Comments: </strong> {{ doctorComments }}</p>
        </div>
      </div>
      <div class="modal-footer">
        <div class="text-right">
          <button
            type="button"
            class="btn btn-light-primary btn-sm"
            (click)="updateMedicine()"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Update Medicine Modal End-->
<!--Rejected Confirmation modal start-->
<div id="cancelModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Cancel</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="checkbox-inline">
              <label class="checkbox">
                <input
                  type="checkbox"
                  name="Checkbox1"
                  [(ngModel)]="isConfirmedCancel"
                />
                <span></span>
              </label>
              Are you sure you want to Cancel this Request?
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-sm btn-default"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary"
          (click)="cancelConfirm()"
        >
          Confirm
        </button>
        <!--        <button type="submit" [disabled]="isButtonClicked" class="btn btn-primary" (click)="deleteGeneral()">Confirm</button>-->
      </div>
    </div>
  </div>
</div>
<!--Return MedicineModal start-->
<div id="returnMedicineModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Return Medicine</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-2">
          <span class="font-weight-bold" style="font-size: 15px"
            >Medicine Name:
          </span>
          <span style="font-size: 15px"> {{ returnMedicineName }}</span>
        </div>
        <div class="mb-5">
          <span class="font-weight-bold" style="font-size: 15px"
            >Balance Quantity:
          </span>
          <span style="font-size: 15px"> {{ qtyToReturn }}</span>
        </div>
        <div class="row">
          <div class="col-md-8">
            <label>Return Qty <span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Quantity"
              (paste)="$event.preventDefault()"
              (keyup)="returnQtyValue()"
              (keypress)="_onlyNumeric($event); noDecimalPoint($event)"
              [(ngModel)]="returnQty"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-sm btn-default"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary"
          (click)="returnMedicine()"
        >
          Return
        </button>
        <!--        <button type="submit" [disabled]="isButtonClicked" class="btn btn-primary" (click)="deleteGeneral()">Confirm</button>-->
      </div>
    </div>
  </div>
</div>

<!--Return MedicineModal end-->
<!--Rejection Reason Modal-->
<div id="rejectReason" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Rejection Reason</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 mb-4">
            <span>{{ this.rejectionReason?.reject_reason }}</span>
          </div>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          (click)="closeModal()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
