<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h3 class="card-label">{{ screenName }}</h3>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button
          class="btn btn-light-primary btn-sm"
          type="button"
          (click)="addLabParameter()"
        >
          Add Parameters
        </button>
        <!--begin::Actions-->
        <!--        <a  class="btn btn-light-primary font-weight-bolder btn-sm">Actions</a>-->
        <!--end::Actions-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card gutter-b mb-3">
        <div class="card-body card-custom">
          <div class="table_top_bar mb-3">
            <div class="row">
              <div class="col-12 col-sm-2">
                <div class="d-block form-group">
                  <input
                    class="form-control form-control-sm"
                    placeholder="Parameter code"
                    name="Test Code"
                    [(ngModel)]="parameterCode"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-3">
                <div class="d-block form-group">
                  <input
                    class="form-control form-control-sm"
                    placeholder="Parameter Name"
                    name="Test Name"
                    [(ngModel)]="parameterName"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-3">
                <div class="form-group">
                  <div class="text-left">
                    <a
                      class="btn btn-light-primary btn-sm px-6 font-weight-bold"
                      ngbTooltip="Search Filter"
                      (click)="searchParameter()"
                      placement="bottom"
                      >Search</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_3"
            >
              <thead>
                <tr>
                  <th style="width: 10%">Code</th>
                  <th style="width: 25%">Title</th>
                  <th style="width: 10%">UOM</th>
                  <th style="width: 25%">Reference Value/comments</th>
                  <th style="width: 10%">Result Format</th>
                  <th style="width: 10%">Status</th>
                  <th style="width: 10%">Update</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let element of pageDataList; let i = index">
                  <td>
                    <span
                      class="text-muted font-weight-bold text-muted d-block"
                      >{{ element?.parameter_code }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{ element?.title }}</span
                    >
                  </td>
                  <td>{{ element?.uom }}</td>
                  <td><span [innerHTML]="element?.range_comments"></span></td>
                  <td>
                    <span
                      *ngIf="
                        element.parameter_type === 'combo';
                        else parameterTypeElse
                      "
                    >
                      {{
                        element?.lab_assess_ranges[0]?.report_type_1 ===
                        "decimal"
                          ? "Number"
                          : (element?.lab_assess_ranges[0]?.report_type_1
                            | titlecase)
                      }}
                    </span>
                    <ng-template #parameterTypeElse>
                      {{ element.parameter_type | titlecase }}
                    </ng-template>
                  </td>
                  <td>
                    <span *ngIf="element?.is_active === true">Active</span>
                    <span *ngIf="element?.is_active === false">Inactive</span>
                  </td>
                  <td>
                    <!--                (click)="parameterDetail(element?.id)"-->
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                      (click)="editParameter(element, i)"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Design\Edit.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="5"
                              y="20"
                              width="15"
                              height="2"
                              rx="1"
                            />
                          </g></svg
                        ><!--end::Svg Icon--></span
                      >
                    </a>
                    <!--                  <button (click)="updateTest(element)" class="btn btn-primary btn-sm" type="button">Update</button>-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!--pagination-->
          <div class="pagination_main mt-4">
            <ngb-pagination
              [(page)]="page"
              (pageChange)="loadPage(page)"
              [pageSize]="perPage"
              [collectionSize]="totalPages"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="false"
              [boundaryLinks]="true"
            ></ngb-pagination>
            <div class="dropdown">
              <a
                class="btn dropdown-toggle btn-light btn-sm"
                role="button"
                id="pagination_btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="page_count">
                  {{ perPage }}
                </span>
                <span class="count_count_icon"></span>
              </a>
              <div class="dropdown-menu" aria-labelledby="pagination_btn">
                <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                  >25</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                  >50</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                  >75</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                  >100</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit('')"
                  >All</a
                >
              </div>
            </div>
          </div>
          <!--pagination End-->
        </div>
      </div>
    </div>
  </div>
</div>
<!--End::Content-->

<div
  class="modal fade"
  id="addMainParameter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal_width" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="!isUpdateParameter">Add Parameter</h5>
        <h5 class="modal-title" *ngIf="isUpdateParameter">Edit Parameter</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="showParameterModalData">
          <app-share-parameter
            (newItemEvent)="nameEventHander($event)"
            (hideValues)="modalHiden = $event"
            (fetchAllLab)="isAddParameterNew = $event"
            (returnParameter)="returnObjectParameter = $event"
            (isUpdate)="isEditParameter = $event"
            (returnUpdated)="returnUpdate = $event"
            [showConfirmation]="false"
            [isUpdated]="isUpdated"
            [parameterId]="assessInvestigationID"
          >
          </app-share-parameter>
        </ng-container>
      </div>
    </div>
  </div>
</div>
